@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ThunderLight';
  src: url('/src/assets/fonts/Thunder-LightLC.woff2') format('woff2'),
       url('/src/assets/fonts/Thunder-LightLC.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Thunder';
  src: url('/src/assets/fonts/Thunder-BoldLC.woff2') format('woff2'),
       url('/src/assets/fonts/Thunder-BoldLC.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

html {
  @apply scroll-smooth;
}

body {
  @apply bg-neutral-silver-700 h-screen text-white font-archivo;
}

.main {
  @apply bg-neutral-black font-archivo flex justify-center h-14 md:h-20 pl-6 pr-4 md:px-0;
}

nav ul {
  @apply flex items-center gap-10;
}

nav li {
  @apply transition-colors duration-300 text-neutral-silver-300 text-lg hover:text-brand-gold;
}

nav a.active {
  @apply text-white;
}

main {
  @apply grow mt-14 xl:mt-20;
}

footer {
  @apply py-10 flex justify-center w-full bg-neutral-black;
}

footer span {
  @apply text-neutral-silver-300 text-sm;
}

footer select {
  @apply bg-transparent text-2xl appearance-none w-12 focus:outline-none;
  background: url('/src/assets/images/icon-arrow-down.svg') no-repeat right;
}

h1, h2, h3, h4, h5 {
  @apply !font-thunder-bold text-white uppercase text-center md:text-left;
}

h2 {
  line-height: 68px;
  @apply text-[76px]
}

h3 {
  @apply text-[64px] leading-[58px];
}

h4 {
  @apply text-5xl md:text-5xl !uppercase;
}

h5 {
  @apply text-4xl md:text-5xl;
}

p {
  @apply !text-base md:!text-lg;
}

table {
  @apply !w-full !border-separate !border-spacing-y-3 md:!border-spacing-y-7;
}

.collapsed  {
  @apply !border-collapse !w-full !border-spacing-y-[-10px] !m-0
}

th {
  @apply text-sm text-left border-b text-white border-neutral-silver-600 py-[11px] capitalize font-normal;
}

.normal-rows td:last-child,
th:last-child {
  border-radius: 0px 0px 0px 0px !important;
}

.normal-rows td:first-child,
th:first-child {
  border-radius: 0px 0px 0px 0px !important;
}

.chest-rows td:last-child,
th:last-child {
  border-radius: 0px 10px 10px 0px;
}

.chest-rows td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}

tr {
  @apply text-neutral-silver-200 cursor-pointer ;
}

tr:hover td {
  @apply text-white;
}

td {
  @apply first:text-white transition duration-500 !p-0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#root {
  @apply h-full;
}

.container {
  @apply px-3 md:px-[60px];
}

.icon path{
  @apply transition-colors fill-white;
}
.icon:hover path {
  @apply fill-brand-gold
}
.social {
  @apply bg-neutral-silver-700 rounded-xl p-3 focus:bg-neutral-black;
}

.social path {
  @apply transition-colors duration-500;
}

.social:hover path {
  @apply fill-brand-gold;
}

.uploader {
  background: 
    radial-gradient(50% 50.00% at 50% 50.00%, #000 0%, rgba(0, 0, 0, 0.00) 100%), rgba(6, 6, 6, 0.50);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23333' stroke-width='4' stroke-dasharray='24%2c 24' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  @apply flex flex-col items-center justify-center rounded-3xl md:rounded-[32px];
  transition: background 0.3s ease;
  position: relative;
}

.uploader.dragging {
  background: radial-gradient(ellipse at center, rgba(6, 6, 6, 0.50) 0%, rgba(6, 6, 6, 0.50) 40%, rgba(97, 75, 138, 0.5) 80%, rgba(97, 75, 138, 0.5) 100%),
  url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='rgb(124, 89, 222)' stroke-width='4' stroke-dasharray='24%2c 24' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.uploader.dragging .container-h5-hidden,
.uploader.dragging p,
.uploader.dragging .input-file-container {
  visibility: hidden;
}
.uploader.dragging .container-h5{
  text-align: center;
  @apply text-brand-uva-light;
}
.uploader.dragging .dragging-title-container {
  @apply flex items-center justify-center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.uploader h2 {
  @apply text-white uppercase text-5xl mb-4;
}

.uploader p {
  @apply text-neutral-silver-200 text-lg font-archivo mb-8;
}

.btn {
  @apply rounded-[10px] font-archivo text-lg font-semibold px-6 py-3 flex items-center justify-center h-[46px] w-full transition duration-300;
}

.btn-primary {
  @apply bg-brand-gold text-black hover:btn-primary-gradient focus:bg-brand-bronze transition-all duration-300 disabled:bg-neutral-silver-500 disabled:text-neutral-silver-300 disabled:pointer-events-none;
}

@layer utilities {
  .btn-primary-gradient {
    @apply bg-gradient-to-b from-brand-gold to-brand-bronze transition-all duration-300;
  }
}

.btn-secondary {
  @apply border-[1.5px] border-brand-gold bg-transparent text-brand-gold hover:border-brand-bronze hover:text-brand-bronze active:bg-brand-bronze active:text-neutral-black disabled:text-neutral-silver-300 disabled:border-neutral-silver-300 disabled:pointer-events-none;
}

.btn-tertiary {
  @apply bg-neutral-silver-600 text-white disabled:bg-neutral-silver-500 disabled:text-neutral-silver-300 hover:bg-brand-uva active:bg-brand-uva;
}

.btn-error {
  @apply bg-error-red text-black disabled:bg-neutral-silver-500 disabled:text-neutral-silver-300 disabled:pointer-events-none;
}

.cover-preview {
  @apply absolute top-0 left-0 w-full h-full rounded-lg flex flex-col items-center justify-center gap-1;
  background: 
    radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), 
    linear-gradient(135deg, rgba(123, 129, 138, 0.85) 0%, rgba(32, 36, 41, 0.85) 54.17%, rgba(31, 34, 39, 0.85) 100%);
}

.search-input {
  background: url('/src/assets/images/icon-search.svg') no-repeat 14px;
  line-height: 20px;
  @apply bg-neutral-silver-700 border rounded-xl border-neutral-silver-500 text-neutral-silver-300 p-3.5 focus:outline-none pl-12 w-full max-w-[360px] hover:border-neutral-300 cursor-pointer transition duration-300 focus:border-brand-gold focus:text-brand-gold;
}

.search-input:hover {
  background: url('/src/assets/images/icon-search-white.svg') no-repeat 14px;
}

.search-input::-webkit-search-cancel-button{
  position: relative;
  right: 0px;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: url('/src/assets/images/icon-close-red.svg');
}

.play-hover {
  @apply w-full h-full;
  background: url('/src/assets/images/icon-play.svg') no-repeat center, 
              linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,.8));
}

.pause-hover {
  @apply w-full h-full;
  background-size: 15px 15px;
  background: url('/src/assets/images/icon-pause.svg') no-repeat center, 
              linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,.8));
}

.cover-hover {
  @apply w-full h-full flex items-center justify-center;
  background: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,.8));
}

.audio-player {
  @apply grid grid-cols-3 px-[60px] pt-4 pb-5 w-full fixed bottom-0 backdrop-blur-[30px];
  background: rgba(37, 41, 46, 0.60);
}

.audio-player-mobile {
  @apply px-3 pt-3 pb-2 rounded-xl fixed bottom-4 mx-3 right-0 left-0 backdrop-blur-[30px] flex flex-col gap-3;
  background: rgba(37, 41, 46, 0.60);
}

.audio-player-mobile-open {
  @apply fixed bottom-4 right-0 left-0 mx-3 z-20;
}

.player-progressbar input[type='range'] {
  @apply hover:cursor-pointer;
  position: relative;
  width: 100%;
}

.player-progressbar input[type='range']::before {
  @apply bg-brand-gold left-0 top-0 absolute h-full;
  content: '';
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.player-progressbar input[type='range']::-webkit-slider-thumb {
  @apply bg-brand-gold w-1.5 h-1.5 rounded-full appearance-none transition-transform duration-300;
}

.player-progressbar input[type='range']:active::-webkit-slider-thumb,
.player-progressbar input[type='range']:hover::-webkit-slider-thumb {
  transform: scale(2);
}

.player-volume input[type='range'] {
  position: relative;
}

.player-volume input[type='range']::before {
  @apply bg-white left-0 top-0 absolute h-full;
  content: '';
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.player-volume input[type='range']::-webkit-slider-thumb {
  @apply bg-white w-1.5 h-1.5 rounded-full appearance-none transition-transform duration-300;
}

.player-volume input[type='range']:active::-webkit-slider-thumb,
.player-volume input[type='range']:hover::-webkit-slider-thumb {
  transform: scale(2);
}

.test path {
  @apply stroke-neutral-silver-200 transition duration-500;
}

.test:hover path {
  @apply stroke-brand-gold;
}

.player-controls path {
  @apply fill-neutral-silver-200 transition duration-500;
}

.player-controls:hover path {
  @apply fill-white;
}

.player-controls-active path {
  @apply fill-brand-gold;
}

.breadcrumb {
  @apply py-2 rounded-full h-fit;
}

.breadcrumb ul {
  @apply list-none flex gap-1 text-neutral-silver-200;
}

.breadcrumb li {
  @apply text-sm relative mr-4 cursor-pointer;
}

.breadcrumb li:after {
  content: '';
  height: 30px;
  width: 30px;
  background: url('/src/assets/images/icon-arrow-right.svg') 0 0 no-repeat;
  @apply inline-block absolute top-0 -right-[30px];
}
.breadcrumb li:first-child:hover {
  @apply text-brand-gold
}
.breadcrumb li:last-child:after {
	content: '';
  background: none;
}

.breadcrumb li:last-child {
  @apply mr-0 cursor-default ;
}

.breadcrumb li:last-child {
  @apply text-white;
}

.toolbar {
  @apply flex items-center;
}

.toolbar button path {
  @apply stroke-white;
}

.signup-cover {
  background: url('/src/assets/images/sign-up.jpg') no-repeat;
  @apply bg-cover h-[260px] md:h-auto md:order-last;
}

.signin-cover {
  background: url('/src/assets/images/sign-in-beta1.jpeg') no-repeat;
  @apply bg-cover h-[260px] md:h-auto md:order-last;
}

.signin-cover-beta {
  background: url('/src/assets/images/sign-in-beta1.jpeg') no-repeat;
  @apply bg-cover h-[260px] md:h-auto md:order-last;
}

.account-type-selector {
  @apply p-8 bg-neutral-silver-700 md:bg-transparent rounded-3xl border-2 border-neutral-silver-600 
         text-center md:max-w-[328px] cursor-pointer transition duration-300 text-neutral-silver-300 hover:text-neutral-silver-200 
         hover:bg-neutral-black hover:border-neutral-black;
}

.account-type-selector p {
  line-height: 22px;
}

.account-type-selector img {
  @apply md:grayscale;
}

.account-type-selector:hover img {
  @apply grayscale-0;
}

.slider-account-type {
  @apply md:!hidden !pb-10;
}

.slider-account-type .swiper-pagination-bullet {
  @apply !bg-neutral-silver-400 !opacity-100;
}

.slider-account-type .swiper-pagination-bullet-active {
  @apply !bg-brand-gold !w-6 !rounded-md;
}

.account-plan {
  @apply relative transition duration-150;
}

.account-plan label {
  @apply flex flex-col bg-neutral-silver-600 rounded-2xl px-6 py-4 cursor-pointer pl-[72px];
}

.account-plan label > h5 {
  @apply text-lg font-semibold text-neutral-silver-200;
}

.account-plan label > p {
  @apply text-sm text-neutral-silver-300;
}

.account-plan input {
  top: calc(50% - 12px);
  @apply absolute left-6;
}

.account-plan input {
  @apply grid place-content-center appearance-none bg-neutral-silver-500 border-2 border-neutral-silver-400 w-6 h-6 rounded-full;
}

.account-plan input::before {
  @apply rounded-full scale-0 transition ease-in-out w-2.5 h-2.5 duration-150;
  content: "";
  box-shadow: inset 1em 1em;
}

.account-plan input:checked {
  @apply border-brand-uva text-brand-uva bg-transparent;
}

.account-plan input:checked::before {
  transform: scale(1.6);
}

.account-plan input:checked + label {
  @apply bg-neutral-black;
}

input[type="checkbox"] {
  @apply bg-transparent appearance-none border-2 border-neutral-silver-400 w-5 h-5 m-0 rounded;
}

input[type="checkbox"]:checked {
  background-image: url('/src/assets/images/icon-check.svg');
  @apply bg-brand-uva border-brand-uva;
}

input[type="checkbox"]:checked::before {
  @apply z-10;
}

input[type="checkbox"]:disabled {
  @apply bg-neutral-silver-500 border-neutral-silver-400;
}

input[type="checkbox"]:focus {
  @apply border-brand-uva;
}

.custom-select {
  background: url('/src/assets/images/icon-arrow-down.svg') no-repeat center right 20px;
  background-size: 16px;
  @apply rounded-xl p-3.5 bg-transparent border border-neutral-silver-400 bg-neutral-silver-700 w-full appearance-none focus:outline-none focus:border-brand-gold;
}

.custom-select:focus {
  background-size: 16px;
  background: url('/src/assets/images/icon-arrow-up-gold.svg') no-repeat center right 20px;
}

.swiper {
  @apply w-full h-full;
}

/*
.swiper-slide {
  @apply text-center flex justify-center items-center max-w-[320px];
}
*/

.swiper {
  mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 100%);
}

.custom-input {
  @apply border border-neutral-silver-400 bg-neutral-silver-700 rounded-xl p-4 w-full hover:border-neutral-300 focus:bg-neutral-black focus:outline-none focus:border-brand-gold leading-5 transition-colors duration-300;
}

.dropdown-list {
  @apply absolute top-8 right-0 flex flex-col bg-black rounded-t-xl border border-neutral-silver-500 rounded-b-xl w-[135px] transition duration-300 z-40 first:rounded-t-xl;
}

.dropdown-item {
  line-height: 20px;
  @apply flex items-center gap-2 px-4 py-3 text-left capitalize transition duration-300 hover:bg-neutral-silver-600;
}

.dropdown-list, .custom-select-options button:first-child {
  @apply rounded-t-xl;
}

.dropdown-list, .custom-select-options button:last-child {
  @apply rounded-b-xl;
}

.account-selector > .swiper {
  @apply pb-10;
}

/*NOTIFICATIONS MOBILE*/
.notification .reply-btn span {
  @apply hidden
} 

/* NOTIFICATIONS WEBPAGE */
.notification {
  @apply w-full bg-neutral-black h-full flex justify-center py-[40px] 
}
.notification h3, p {
  @apply text-center
}
.notification .row {
  @apply bg-neutral-silver-600 w-full rounded-lg md:space-y-3 space-y-4 p-3 md:pr-4 md:pl-3 md:py-3  
}
.notification .over {
  @apply bg-neutral-black !important
}
@media screen and (min-width: 1024px) {
  /* … */
  .notification .reply-btn {
   @apply flex uppercase text-base items-center rounded-lg px-2 py-1  bg-neutral-silver-700 text-brand-uva-light;
  }
  .notification .isOpen  {
    @apply bg-neutral-silver-500 !important
  }
  .notification .reply-btn span {
    @apply flex
  } 
  
}
.notification .options {
 @apply flex justify-center space-x-2 w-full
}
.notification .options button {
  @apply py-3 px-4 flex gap-x-2 items-center
         rounded-xl font-archivo font-semibold 
         bg-neutral-silver-700 text-neutral-silver-200 text-[18px];
}
.notification .options button span {
 @apply bg-neutral-silver-600 rounded-full px-1 capitalize text-sm;
}
.notification .options .isActive {
 @apply bg-brand-gold  text-neutral-black
}
.notification .options .isActive span  {
  @apply bg-brand-gold/40 px-1 
}


/* ACCOUNT */
.container-head-account {
  @apply flex mt-3 md:mt-5 mb-6 md:mb-8 px-3 md:px-0  !text-left
}
.container-items-account {
  @apply flex flex-col gap-y-2 !text-left;
}
.container-items-account h5 {
  @apply font-archivo normal-case	text-neutral-silver-200 text-base md:text-lg text-left;
}
.container-items-account h3 {
  @apply text-left
}



/* AACOUNT DATA*/

.container-accountData {
 @apply bg-neutral-silver-700 lg:w-2/5 py-8 px-6 lg:p-8 rounded-2xl space-y-6
}
.container-accountData h5, h4, span {
  @apply font-archivo normal-case	text-left ;
}
.container-accountData .item {
  @apply flex flex-col gap-y-2
}
/* ACOUNT PLAN */
.container-accountPlan {
  @apply bg-neutral-silver-700 lg:w-3/5 py-8 px-6 lg:px-12 rounded-2xl space-y-6 flex flex-col ;
}
.container-accountPlan h5, h4, span {
  @apply font-archivo normal-case	text-left ;
}
/* CARROUCEL */
.button-carousel-container {
  width: 80%;
  margin: 0 auto;
}

.button {
  border: none;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease;
}

.button.active {
  transform: scale(1.1); /* Agranda el botón activo */
}

/* SHARE */
.share-container {
  @apply bg-neutral-silver-700 w-full md:w-[680px] px-6 py-8 gap-y-5 md:px-8 md:py-12 md:gap-y-6 rounded-2xl 
  flex flex-col items-start md:items-center;
}
.share-button {
  @apply py-3 px-4 text-base font-semibold font-archivo flex items-center justify-center gap-2 rounded-xl 
       bg-neutral-silver-700 text-neutral-silver-200 transition-colors duration-200;
  transition: transform 0.3s ease; /* Agregamos una transición de transformación para suavizar la animación. */
  transform: translateX(0); /* Restablecemos la transformación a su posición original. */
  align-items: center; /* Centramos verticalmente el contenido en el botón. */
  min-width:160px;
}
.share-button.isActive {
  @apply bg-brand-gold  text-neutral-black;
}

.autocomplete-container {
  @apply absolute mt-1 border border-neutral-silver-500 rounded-xl w-full divide-y divide-neutral-silver-600 z-30;
}

.autocomplete-container div:first-child {
  @apply rounded-t-xl;
}

.autocomplete-container div:last-child {
  @apply rounded-b-xl;
}

.autocomplete-item { 
  @apply flex gap-2 items-center px-4 py-3 transition-colors duration-500 bg-neutral-black hover:bg-neutral-silver-600 hover:cursor-pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

/* probando tab */
.contenedor-boton {
  perspective: 800px;
}


.boton-sobresaliente {
  font-size: 16px;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #f0ad4e; /* Color de 'btn-warning' de Bootstrap */
  color: white;
  cursor: pointer;
  outline: none;
  position: relative;
  transform: translateZ(30px);
  transition: transform 0.25s ease-out;
}

.boton-sobresaliente::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: rgba(0,0,0,0.1);
  z-index: -1;
}

.boton-sobresaliente:hover {
  transform: translateZ(50px) scale(1.05);
}

/* page loader */

.c-header {
  height: 4px;
  width: 100%;
  position: relative;
}
.c-header-loader {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.c-slidingLoader {
  position: relative;
  height: 4px;
  width: 100%;
  overflow: hidden;
}
.c-slidingLoader-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: loading 1s infinite alternate cubic-bezier(0.77, 0, 0.175, 1);
          animation: loading 1s infinite alternate cubic-bezier(0.77, 0, 0.175, 1);
}

.c-slidingLoader-inner::before {
  @apply bg-brand-gold;
  content: "";
  display: block;
  background-color: #;
  position: relative;
  height: 100%;
  width: 30%;
  transform: translateX(-50%);
}
@-webkit-keyframes loading {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.button-tab {
  @apply text-neutral-silver-200 hover:text-brand-gold text-lg py-2 border-b-2 border-transparent hover:border-brand-gold transition duration-300 capitalize;
}

.button-tab.active {
  @apply text-brand-gold border-brand-gold;
}

.custom-table table {
  border-collapse: inherit;
  border-spacing: 0 11px;
  @apply text-base;
}

.custom-table {

}

.version-options {
  @apply relative transition duration-150 flex flex-col gap-3;
}

.version-options label {
  @apply flex flex-col bg-neutral-silver-600 rounded-2xl px-5 py-4 cursor-pointer pl-[72px];
}

.version-options label > h5 {
  @apply text-lg font-semibold text-neutral-silver-200;
}

.version-options label > p {
  @apply text-sm text-neutral-silver-300;
}

.version-options input {
  top: calc(50% - 12px);
  @apply absolute left-6;
}

.version-options input {
  @apply grid place-content-center appearance-none bg-neutral-silver-500 border-2 border-neutral-silver-400 w-6 h-6 rounded-full;
}

.version-options input::before {
  @apply rounded-full scale-0 transition ease-in-out w-2.5 h-2.5 duration-150;
  content: "";
  box-shadow: inset 1em 1em;
}

.version-options input:checked {
  @apply border-brand-uva text-brand-uva bg-transparent;
}

.version-options input:checked::before {
  transform: scale(1.6);
}

.version-options input:checked + label {
  @apply bg-neutral-black;
}

.modal-backdrop {
  @apply fixed w-screen h-screen inset-0 bg-black bg-opacity-70;
  z-index: 9999;
}

.modal-container {
  @apply flex justify-center items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none;
}

.modal-wrapper {
  @apply relative w-full md:w-auto md:my-6 mx-auto md:min-w-[520px] md:max-w-3xl;
}

.modal-panel {
  @apply border-0 rounded-t-[20px] md:rounded-[20px] relative flex flex-col gap-3 w-full bg-neutral-silver-700 outline-none focus:outline-none p-6 md:p-10;
}

.toolbar-button {
  @apply p-2 rounded-full bg-neutral-silver-600;
}

.toolbar-button path {
  @apply stroke-white transition duration-500;
}

.primary:hover path {
  @apply stroke-brand-gold;
}

.alert:hover path {
  @apply stroke-error-red;
}

.storage-indicator {
  @apply flex items-center justify-end gap-3 md:gap-4;
}

.context-button {
  @apply p-[7px] rounded-[10px] transition duration-500 hover:bg-neutral-silver-700 border-[3px] border-transparent active:border-gray-700 hover:border-neutral-silver-600;
}

.context-button-container {
  @apply bg-neutral-silver-600 rounded-xl pt-2 px-1.5 pb-1.5 absolute bottom-12 right-0 overflow-hidden;
}
.bg-custom-opacity {
  background: rgba(67, 71, 79, 0.30);
  backdrop-filter: blur(25px);
  @apply bg-opacity-30
}

.tab-btn {
  @apply py-3 px-4 font-archivo font-semibold text-base  rounded-xl flex items-center gap-x-2 
  bg-neutral-silver-700 text-neutral-silver-200
}
.tab-btn path {
  @apply transition-colors duration-500 fill-neutral-silver-200;
}
.tab-btn:hover path {
  @apply fill-brand-gold; 
}
.tab-btn:hover {
  @apply text-brand-gold;
}
.tab-btn:active path {
  @apply fill-neutral-silver-200;
}
.tab-btn.isActive {
  @apply !bg-brand-gold text-neutral-black
}
.tab-btn.isActive path {
  @apply fill-neutral-black
}

.web-disabled path {
  @apply fill-neutral-silver-200 transition duration-500;
}

.web-disabled:hover path {
  @apply fill-white;
}

.icon-modal path {
  @apply fill-neutral-silver-300
}

.icon-modal:hover path {
  @apply fill-brand-gold;
}

.icon-active path {
  @apply fill-brand-gold;
}

.custom-select-input {
  @apply transition duration-300 border border-neutral-silver-400 bg-neutral-silver-700 rounded-xl p-4 w-full focus:outline-none focus:border-brand-gold leading-5 cursor-pointer;
}

.custom-select-options {
  @apply absolute right-0 flex flex-col bg-black rounded-t-xl border border-neutral-silver-500 rounded-b-xl w-full transition duration-300 z-40 first:rounded-t-xl divide-y divide-neutral-700 overflow-y-auto overflow-x-hidden max-h-[150px];
}

.custom-select-input-min {
  @apply transition duration-300 bg-neutral-silver-700 py-4 pr-4 text-2xl leading-5 cursor-pointer bg-transparent focus:outline-none !w-[70px];
}

.custom-select-input-child {
  @apply transition duration-300 bg-neutral-silver-700 py-4 pr-4 cursor-pointer bg-transparent focus:outline-none !w-[120px];
}

/* menu */

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  transform: scale(0.5)
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  @apply bg-white;
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}

.empty-title {
 @apply !font-archivo !font-semibold !text-[22px] md:!text-[28px] !normal-case;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
}

.toast {
  position: fixed;
  top: 5rem; /* Ajusta la distancia desde la parte superior según sea necesario */
  right: 5rem; /* Ajusta la distancia desde la derecha según sea necesario */
  background-color: white;
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999; /* Asegura que esté en la parte superior */
}

.toast-container {
  position: fixed;
  z-index: 9999;
  right: 5rem;
  top: 5rem;
  @apply w-auto;
}

.discount-container{
  width: Hug (110px)px;
height: Hug (38px)px;
padding: 8px 16px 8px 16px;
gap: 10px;
border-radius: 12px 12px 12px 12px;
opacity: 1;
background: linear-gradient(180deg, #A06819 0%, #FFBE62 100%);
color:black;
font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .toast-container {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 5rem;
  }
}

.btn-absolute {
  @apply absolute whitespace-nowrap text-neutral-silver-100 px-3 py-2 bg-neutral-silver-600 rounded-xl text-center transform -translate-x-1/2 -top-11 left-1/2;
}

.pro-container {
  background: linear-gradient(76deg, #15181C -7.73%, #A07A42 100%);
}
@media (max-width: 1280px) {
  .pro-container {
    background: linear-gradient(218deg, #15181C 22.02%, #A07A42 77.84%);  
  }
}